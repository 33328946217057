<template>
    <div ref="chartContainer" class="echarts-chart" style="width: 100%; height: 400px;"></div>
  </template>
  
  <script>
  import * as echarts from 'echarts';

  export default {
    name: 'BarChart',
    props: {
      xAxisData: {
        type: Array,
        required: true
      },
      seriesData: {
        type: Array,
        required: true
      },
      drilldownData: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        myChart: null,
      };
    },
    mounted() {
      this.initChart();
    },
    methods: {
      initChart() {
        const chartDom = this.$refs.chartContainer;
        this.myChart = echarts.init(chartDom);
        this.updateChart();
      },
      updateChart() {
        const rootStyle = getComputedStyle(document.documentElement);
        const colorSecondary = rootStyle.getPropertyValue('--color-secondary').trim();
        const textColor = rootStyle.getPropertyValue('--text-color-medium').trim();

  
        const option = {
          xAxis: {
            data: this.xAxisData,
            axisLabel: {
              color: textColor
            }
          },
          yAxis: {},
          dataGroupId: '',
          animationDurationUpdate: 500,
          series: {
            type: 'bar',
            id: 'sales',
            data: this.seriesData,
            color: colorSecondary,
            universalTransition: {
              enabled: true,
              divideShape: 'clone'
            }
          }
        };
  
        this.myChart.setOption(option);
  
        this.myChart.on('click', (event) => {
          if (event.data) {
            const subData = this.drilldownData.find((data) => data.dataGroupId === event.data.groupId);
            if (!subData) return;
  
            this.myChart.setOption({
              xAxis: {
                data: subData.data.map((item) => item[0]),
                axisLabel: {
                  color: textColor
                }
              },
              series: {
                type: 'bar',
                id: 'sales',
                dataGroupId: subData.dataGroupId,
                data: subData.data.map((item) => item[1]),
                color: colorSecondary,
                universalTransition: {
                  enabled: true,
                  divideShape: 'clone'
                }
              },
              graphic: [
                {
                  type: 'text',
                  left: 50,
                  top: 20,
                  style: {
                    text: 'Voltar',
                    fontSize: 18
                  },
                  onclick: () => {
                    this.myChart.setOption(option);
                  }
                }
              ]
            });
          }
        });
      }
    },
    watch: {
      xAxisData () {
        this.updateChart();
      },
      seriesData () {
        this.updateChart();
      },
      drilldownData () {      
        this.updateChart();
      }
    }
  };
  </script>
  
  <style scoped>
  .echarts-chart {
    width: 100%;
    height: 400px;
  }
  </style>
  