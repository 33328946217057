<template>
  <div ref="chart" :style="{ width: width, height: height }"></div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  name: 'PieChart',
  props: {
    chartData: {
      type: Array,
      default: () => []
    },
    title: {
      type: Object,
      default: () => ({
        text: '',
        subtext: '',
        left: 'center'
      })
    },
    width: {
      type: String,
      default: '600px'
    },
    height: {
      type: String,
      default: '400px'
    }
  },
  mounted() {
    this.initChart();
  },
  methods: {
    initChart() {
      const chart = echarts.init(this.$refs.chart);
      const rootStyle = getComputedStyle(document.documentElement);
      const colorPrimary = rootStyle.getPropertyValue('--color-primary').trim();
      const colorSecondary = rootStyle.getPropertyValue('--color-secondary').trim();
      const textColor = rootStyle.getPropertyValue('--text-color-medium').trim();

      const option = {
        title: this.title,
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left',
          textStyle: {
            color: textColor
          }
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: '50%',
            data: this.chartData,
            color: [colorSecondary, colorPrimary],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              color: textColor
            }
          }
        ]
      };

      chart.setOption(option);
    }
  },
  watch: {
    chartData: 'initChart',
    title: 'initChart',
    width: 'initChart',
    height: 'initChart'
  }
};
</script>

<style scoped>
</style>
